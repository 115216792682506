import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store"; // Importing Redux store
import history from "./history"; // Importing browser history
import Layouts from "./layouts"; // Importing Layouts component
import { THEME_CONFIG } from "./configs/AppConfig"; // Importing theme configuration
import "./lang"; // Importing language file
import { SessionValidationProvider } from "components/util-components/ValidateSession"; // Importing SessionValidationProvider component

// Define themes for the theme switcher
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`, // Dark theme CSS file path
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`, // Light theme CSS file path
};

// Main App component
function App() {
  return (
    <div className="App">
      {" "}
      {/* Main container with class App */}
      <Provider store={store}>
        {" "}
        {/* Redux Provider wrapping the app to provide Redux store */}
        <BrowserRouter history={history}>
          {" "}
          {/* React Router BrowserRouter for navigation */}
          {/* ThemeSwitcherProvider for theme switching */}
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <SessionValidationProvider>
              {" "}
              {/* SessionValidationProvider for session validation */}
              <Layouts />{" "}
              {/* Layouts component for rendering different layout views */}
            </SessionValidationProvider>
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
