import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "utils/service";

export const INITIAL_STATE = {
    loading: true,
    message: "",
    showMessage: false,
    paymentTypes: []
}

// Payment Types GET
export const GetAllPaymentTypes = createAsyncThunk(
    "payment/v1/payment-types/GET",
    async (params, { rejectWithValue }) => {
        try {
            const response = await API.get(`/payment/v1/payment-types`);
            if(response.status === 200){
                localStorage.setItem("paymentTypes",JSON.stringify(response?.data?.payload))
            }
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

// All Payment Methods GET
export const GetAllPaymentMethods = createAsyncThunk(
    "payment/v1/payment-types/GET",
    async (params, { rejectWithValue }) => {
        try {
            let startDateParam = params.startDate
                ? `&startDate=${params.startDate}`
                : "";
            let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
            let updatedByParam = params.updatedBy
                ? `&filterBy=${params.updatedBy}`
                : "";
            let query = params.search ? `&query=${params.search}` : "";
            const response = await API.get(
                `/payment/v1/payment-method-master/list?page=${params.page
                }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
                    ? `&sort=${params.column},${params.order}`
                    : "&sort=id,desc"
                }`
            );

            return response?.data?.payload;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

// All Payment Methods without listing GET
export const GetAllPaymentMethodsOnly = createAsyncThunk(
    "payment/v1/payment-types/GET",
    async (params, { rejectWithValue }) => {
        try {
            const response = await API.get('/payment/v1/payment-method-master');
            return response?.data?.payload;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);



// Payment Method By Id GET
export const GetPaymentMethodById = createAsyncThunk(
    "payment/v1/payment-types/GET",
    async (params, { rejectWithValue }) => {
        try {
            
            const response = await API.get(`/payment/v1/payment-method-master/${params}`);

            return response?.data?.payload;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

// All Payment Methods POST
export const PaymentMethodPost = createAsyncThunk(
    "payment/v1/payment-types/POST",
    async (data, { rejectWithValue }) => {
        try {
            const response = await API.post(
                `/payment/v1/payment-method-master`,
                data
            );

            return response?.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);
// All Payment Methods PATCH
export const PaymentMethodPatch = createAsyncThunk(
    "payment/v1/payment-types/PATCH",
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await API.patch(
                `/payment/v1/payment-method-master/${id}`,
                data
            );

            return response?.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

// All Payment Methods DELETE
export const DeletePaymentMethod = createAsyncThunk(
    "payment/v1/payment-types/DELETE",
    async (params, { rejectWithValue }) => {
        try {
            const response = await API.delete(
                `/payment/v1/payment-method-master/${params}`
            );

            return response?.data?.payload;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);



const paymentMethodsSlice = createSlice({
    name: 'payments',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(GetAllPaymentTypes.pending, (state, action) => {
                state.loading = true
            })
            .addCase(GetAllPaymentTypes.fulfilled, (state, action) => {
                state.loading = false
                state.paymentTypes = action.payload
            })
            .addCase(GetAllPaymentTypes.rejected, (state, action) => {
                state.loading = false;
                state.message = action.payload;
                state.showMessage = true;
            })

    }
})

export default paymentMethodsSlice.reducer